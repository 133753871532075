<template>
    <v-simple-table>
        <thead>
            <th>
                ธนาคาร
            </th>
            <th>
                จำนวนเดือน
            </th>
        </thead>
        <tbody>
            <tr v-for="(bank, index) in $attrs.value" :key="index">
                <td>
                    {{ bank.name }}
                </td>
                <td>
                    <v-checkbox
                        v-for="(value, term_key, term_index) in bank.terms"
                        :key="index + '_term_' + term_index"                        
                        v-model="$attrs.value[index].terms[term_key]"                        
                        :label="term_key + ' เดือน'"
                    >
                    </v-checkbox>                    
                </td>
            </tr>
            <!--
            <tr v-for="(bank, index) in banks" :key="index">
                <td>
                    {{ bank.name }}
                </td>
                <td>              
                    <checkbox
                    v-for="(value, term_key, term_index) in bank.terms"
                    :key="index + '_term_' + term_index"
                    class="kanit t-m"
                    :checked="value"
                    @change="$emit('change', { bank_index: index, term: term_key , event: $event})"
                    >{{ term_key }} เดือน</checkbox>
                    
                </td>
            </tr>
            -->
        </tbody>
    </v-simple-table>
</template>
<script>
export default {
  /*
    props: {
        banks: {
            type: Array,
            required: true
        }
    },
    */
  /*
  data() {
    return {
      banks: [],
    }
  },
  watch: {
    banks: function (value) {
      this.$emit('input', value)
    },
  },
  created() {
    this.banks = this.$attrs.value
    console.log(this.banks)
  },
  */
}
</script>
<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}

table thead {
  background-color: #d84c52;
  color: #ffffff;
}

table thead th,
table tbody tr td {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>